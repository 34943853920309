<template>
  <aside
    class="the-side-navigation main-Side-nav -translate-x-full [&.active]:translate-x-0 md:translate-x-0 sm:max-w-0 sm:overflow-x-hidden whitespace-nowrap sm:[&.active]:!max-w-[16rem] !transition-all !duration-300 !z-[44]"
    :class="{ active: active }"
  >
    <div
      class="overflow-y-scroll py-3 px-3 pb-[5rem] h-full bg-white dark:bg-gray-800 custom-scroll"
    >
      <client-only>
        <section>
          <ElementsUtilsAppButton
            mode="icon"
            :icon="['fas', 'square-minus']"
            outline
            class="!border-none ml-auto"
            color="gray"
            title="ยุบทั้งหมด"
            @click="onCollapseAll"
          />
        </section>

        <!-- no submenus -->
        <template
          v-for="menu in managementNavigation.filter((i: any) => !i.subMenus).filter((j: any) => j.active)"
          :key="menu.name"
        >
          <router-link
            class="navigation__click-target gap-3"
            :to="menu.route"
            tabindex="-1"
            draggable="true"
            ondrop="handleDrop"
          >
            <font-awesome-icon class="w-4" :icon="['fas', menu.icon]" />
            <span class="mr-auto">{{ menu.name }}</span>
          </router-link>
        </template>

        <!-- has submenus -->
        <template
          v-for="menu in managementNavigation
            .filter((i : any) => i.subMenus)
            .filter((j : any) => j.subMenus?.some((subMenu: any) => subMenu.active))"
          :key="menu.name"
        >
          <ElementsUtilsAppLinkGroup
            ref="linkGroupEls"
            :group-name="menu.name"
            :group-icon="['fas', menu.icon || '']"
            :links="menu.subMenus || []"
          ></ElementsUtilsAppLinkGroup>
        </template>
      </client-only>
    </div>
  </aside>
</template>

<script setup lang="ts">
import LinkGroup from "@/components/elements/utils/AppLinkGroup.vue";

const { managementNavigation } = usePagePermission();

const { active } = inject<any>("navigation");

const linkGroupEls = ref<InstanceType<typeof LinkGroup>[] | null>(null);
function onCollapseAll() {
  if (linkGroupEls.value === null) return;
  for (const el of linkGroupEls.value) {
    el.setActive(false);
  }
}
</script>

<style>
.navigation__click-target .router-link-exact-active,
.navigation__click-target.router-link-exact-active {
  @apply dark:!bg-opacity-10 !bg-opacity-10 !bg-primary-600 !text-primary-600 dark:!bg-primary-500  dark:!text-primary-500;
}
.navigation__click-target {
  @apply flex justify-between p-2 px-3 my-2 rounded-md bg-transparent dark:bg-transparent text-gray-500 dark:text-gray-400 transition-all duration-300 cursor-pointer;
  @apply hover:bg-gray-100 dark:hover:bg-gray-600 hover:px-4;
}
</style>

<template>
  <div class="relative">
    <!-- Apps -->
    <button
      v-show="temp"
      type="button"
      class="nav-button-icon"
      @click="activeMenu = !activeMenu"
    >
      <!-- Icon -->
      <font-awesome-icon class="w-5 h-5" :icon="['fas', 'grid-2']" />
    </button>
    <!-- Dropdown menu -->
    <div
      v-on-click-outside="closeModal"
      class="absolute top-[26px] left-[-230px] overflow-hidden z-50 my-4 w-[300px] max-w-[300px] text-base list-none bg-white divide-y divide-gray-100 shadow-lg dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
      :class="{ hidden: !activeMenu }"
    >
      <div
        class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-600 dark:text-gray-300"
      >
        เมนู
      </div>
      <client-only>
        <div v-if="posLists" class="grid grid-cols-3 gap-3 p-4">
          <template
            v-for="(item, index) in posNavigation"
            :Key="`pos-menu-${index}`"
          >
            <a
              v-if="item?.active"
              class="block py-2.5 px-3 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group cursor-pointer"
              @click="clickMenu(item?.route)"
            >
              <font-awesome-icon
                class="w-7 h-7 mx-auto mb-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                :icon="['fas', item?.icon]"
              />

              <div class="text-sm text-gray-900 dark:text-white">
                {{ item?.name }}
              </div>
            </a>
          </template>

          <div
            class="block py-2.5 px-3 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group cursor-pointer"
            @click="
              setColorTheme($colorMode.preference === 'dark' ? 'light' : 'dark')
            "
          >
            <!-- Icon -->
            <font-awesome-icon
              class="w-7 h-7 mx-auto mb-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
              :class="{ hidden: !darkTheme }"
              :icon="['fas', 'sun-bright']"
            />
            <font-awesome-icon
              class="w-7 h-7 mx-auto mb-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
              :class="{ hidden: darkTheme }"
              :icon="['fas', 'moon']"
            />
            <div class="text-sm text-gray-900 dark:text-white">
              {{ $colorMode.preference === "dark" ? "สว่าง " : "มืด" }}
            </div>
          </div>
        </div>
      </client-only>
    </div>
  </div>
</template>
<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components";
type Theme = "light" | "dark";

const { posNavigation } = usePagePermission();
const darkTheme = ref(true);
const temp = ref(false);
const posLists = ref(false);

const activeMenu = ref(false);

onMounted(() => {
  temp.value = true;
  darkTheme.value = useColorMode().preference === "dark";
  setTimeout(() => {
    posLists.value = true;
  }, 300);
});
function closeModal() {
  activeMenu.value = false;
}
const setColorTheme = (newTheme: Theme) => {
  useColorMode().preference = newTheme;
  darkTheme.value = useColorMode().preference === "dark";
};

const clickMenu = (path: string) => {
  activeMenu.value = false;
  navigateTo(path);
};
</script>

<template>
  <div class="app-link-group my-2">
    <header
      class="navigation__click-target group-hover:!text-primary-600 dark:group-hover:!text-primary-500 hover:!px-3 select-none !my-0"
      @click="active = !active"
    >
      <span class="flex gap-2 items-center">
        <font-awesome-icon class="w-5" :icon="groupIcon" />
        <span class="flex gap-2 flex-wrap relative">
          <span
            class="group-hover:translate-y-[-25%] group-hover:opacity-0 transition-all duration-300"
          >
            {{ groupName }}
          </span>
        </span>
      </span>
      <button>
        <font-awesome-icon class="w-4" :icon="['fas', 'chevron-down']" />
      </button>
    </header>
    <ul ref="targetEl" class="overflow-y-hidden overflow-x-hidden">
      <template v-for="{ name, route, active: show } in links" :key="route">
        <li v-if="show">
          <router-link
            class="navigation__click-target !pl-10"
            :to="route"
            tabindex="-1"
            @click="setCurrentPageLabel(name)"
          >
            <span>
              {{ name }}
            </span>
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  groupName: string;
  groupIcon: string[];
  links: {
    name: string;
    icon?: string;
    route: string;
    active?: boolean;
  }[];
}>();

const active = ref(false);
const targetEl = ref<HTMLElement | null>(null);
const gsap = useNuxtApp().$gsap;
const { setCurrentPageLabel } = inject<any>("navigation");
// create gsap height animation for targetEl, using linear easing
onMounted(() => {
  handleAnimate(active.value);
});
watch(
  active,
  (value) => {
    handleAnimate(value);
  },
  { immediate: true }
);
function handleAnimate(activeValue: boolean) {
  if (targetEl.value) {
    gsap.to(targetEl.value, {
      height: activeValue ? "auto" : 0,
      duration: 0.3,
      ease: "power2",
    });
  }
}

defineExpose({
  active,
  setActive(value: boolean) {
    active.value = value;
  },
});
</script>

<style scoped>
.app-link-group:has(.router-link-exact-active) > header {
  @apply text-primary-600 dark:text-primary-500;
}
.app-link-group:has(.router-link-exact-active) .current-page-label {
  @apply inline-block;
}
</style>

<template>
  <div class="flex justify-start items-center">
    <div class="flex items-center justify-between mr-4">
      <!--      <img-->
      <!--        src="/logo/terra-logo.svg"-->
      <!--        class="mr-3 h-8 w-auto"-->
      <!--        alt="Terra Pos Logo"-->
      <!--      />-->
      <!--      <span-->
      <!--        class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"-->
      <!--      >-->
      <!--        &lt;!&ndash;        Terra &ndash;&gt;-->
      <!--      </span>-->
    </div>
  </div>
</template>

<template>
  <div>
    <button
      id="user-menu-button"
      type="button"
      class="flex text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
      aria-expanded="false"
      data-dropdown-toggle="UserAccountDropdown"
    >
      <span class="sr-only">Open user menu</span>
      <ClientOnly>
        <div
          class="uppercase w-8 h-8 rounded-full flex flex-col justify-center items-center text-white font-medium bg-gradient-to-tr from-yellow-600 to-yellow-400"
        >
          {{ nameAvatar }}
        </div>
      </ClientOnly>
    </button>
    <!-- Dropdown menu -->
    <div
      id="UserAccountDropdown"
      class="hidden z-50 my-4 w-56 text-base list-none bg-white divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
    >
      <div class="py-3 px-4">
        <client-only>
          <span
            class="block text-sm font-semibold text-gray-900 dark:text-white"
          >
            {{ useName }}
          </span>
          <span class="block text-sm text-gray-900 truncate dark:text-white">
            {{ useEmail }}
          </span>
        </client-only>
      </div>

      <ul
        class="text-gray-700 dark:text-gray-400"
        aria-labelledby="UserAccountDropdown"
      >
        <li>
          <nuxt-link
            to="/profile"
            class="flex py-2 px-4 gap-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <font-awesome-icon
              class="w-5 h-5"
              :icon="['fas', 'address-card']"
            />
            <span>บัญชีผู้ใช้</span>
          </nuxt-link>
        </li>
        <li v-if="!isOneBranch">
          <nuxt-link
            to="/branch-selector"
            class="flex py-2 px-4 gap-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <font-awesome-icon class="w-5 h-5" :icon="['fas', 'sitemap']" />
            <span>เปลี่ยนสาขา</span>
          </nuxt-link>
        </li>
      </ul>

      <ul
        class="text-gray-700 dark:text-gray-300 static"
        :class="{
          'sm:hidden': terminalId && terminalId !== '',
        }"
        aria-labelledby="UserAccountDropdown"
      >
        <li>
          <div
            class="block py-2.5 px-4 text-sm rounded-b-lg hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white select-none cursor-pointer"
            @click="logout"
          >
            ออกจากระบบ
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
const { profile, branch, terminalId } = useInitialLocalStore();

const useName = computed(() => profile.value?.name || "");

const useEmail = computed(() => profile.value?.email || "");

const nameAvatar = computed(() => profilePlaceholder(useName.value));
const isOneBranch = ref<boolean>(true);
onMounted(() => {
  isOneBranch.value = branch.value?.isOneBranch || false;
});

const { logout } = inject<any>("the-header");
</script>

<template>
  <div
    ref="mainElement"
    class="bg-gray-50 dark:bg-gray-900 flex flex-col w-full min-h-screen [ text-black dark:text-white ]"
  >
    
    <!-- Interfaces -->
    <TheHeader />
    <TheSideNavigation v-show="hidden" />
    <div
      class="BACKDROP z-[43] bg-black bg-opacity-40 backdrop-blur-md fixed inset-0 opacity-0 pointer-events-none transition-all duration-300 block md:hidden"
      :class="active ? 'opacity-100 pointer-events-auto' : ''"
      @click.self="onClickBackdropInSmallScreen"
    ></div>
    <!-- Main Content -->
    <main
      class="flex flex-col w-full h-full grow transition-all duration-300 overflow-hidden bg-white dark:bg-gray-900"
      :class="[{ 'md:pl-64': active }]"
    >
      <slot></slot>
    </main>
  </div>
</template>
<script setup lang="ts">
import { initFlowbite } from "flowbite";
import TheHeader from "~/components/layout/TheHeader.vue";
import TheSideNavigation from "~/components/layout/TheSideNavigation.vue";

useHead({
  bodyAttrs: {
    class: "overflow-y-scroll",
  },
});

// # Side Navigation Controller
const MD_BREAKPOINT = 768;
const hidden = ref(false);
const active = ref(false);
const currentPageLabel = ref("");

provide("navigation", {
  active,
  setActive(value: boolean) {
    active.value = value;
  },
  toggleActive() {
    active.value = !active.value;
  },
  currentPageLabel,
  setCurrentPageLabel(value: string) {
    currentPageLabel.value = value;
  },
});

function lockWhenSideNavActiveOnSmallScreen() {
  if (active.value && window.innerWidth < MD_BREAKPOINT) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "scroll";
  }
}

watch(
  () => useRoute().fullPath,
  () => {
    // if small screen, close when route change
    if (window.innerWidth < MD_BREAKPOINT) {
      active.value = false;
    }
  }
);
watch(
  () => active.value,
  () => {
    lockWhenSideNavActiveOnSmallScreen();
  }
);

function onClickBackdropInSmallScreen() {
  if (window.innerWidth < MD_BREAKPOINT) {
    active.value = false;
  }
}

onMounted(() => {
  if (window.innerWidth < MD_BREAKPOINT) {
    active.value = false;
  }
  initFlowbite();
  const { isOneManagementPermissionActive } = useInitialLocalStore();
  hidden.value = isOneManagementPermissionActive.value as any;
});
</script>

<template>
  <nav
    class="sticky top-0 left-0 bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 z-[45] w-full"
  >
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex justify-start items-center">
        <ClientOnly>
          <button
            v-if="!disabledNavigationIcon"
            class="nav-button-icon mr-2"
            @click="toggleActive"
          >
            <font-awesome-icon
              class="w-5 h-5"
              :icon="['fas', 'bars-staggered']"
            />
          </button>
        </ClientOnly>
        <header-icon />
      </div>
      <ClientOnly>
        <div
          v-if="branch?.role !== 'ผู้บริหาร'"
          class="text-sm lg:text-4xl font-medium text-primary-500"
        >
          {{ branch?.name }}
        </div>
      </ClientOnly>

      <div class="flex items-center lg:order-2 gap-2">
        <ClientOnly>
          <div class="self-end hidden lg:flex">
            {{ useName }}
          </div>
          <Button
            v-if="terminalId && terminalId !== ''"
            color="yellow"
            class="hidden sm:inline-block"
            @click="logout"
          >
            ออกจากระบบ
          </Button>
        </ClientOnly>
        <navigation-app />
        <user-account />
      </div>
    </div>
  </nav>
</template>
<script setup>
import { Button } from "flowbite-vue";
import HeaderIcon from "~/components/layout/element/HeaderIcon.vue";
import NavigationApp from "~/components/layout/element/NavigationApp.vue";
import UserAccount from "~/components/layout/element/UserAccount.vue";

const {
  isOneManagementPermissionActive,
  profile,
  posPermission,
  managementPermission,
  isOnePosPermissionActive,
  branch,
  terminalId,
  terminalName,
} = useInitialLocalStore();
const oneManagementPermission = ref(true);
const useName = computed(() => profile.value?.name || "");

// show navigation hamburger icon
const { managementNavigation } = usePagePermission();
const disabledNavigationIcon = computed(() => {
  return managementNavigation.value.every((item) => {
    if (item.subMenus) {
      return item.subMenus.every((subMenu) => {
        return subMenu.active === false;
      });
    } else {
      return item.active === false;
    }
  });
});

watchEffect(() => {
  oneManagementPermission.value =
    isOneManagementPermissionActive.value === "true" ||
    isOneManagementPermissionActive === true;
});

const { toggleActive } = inject("navigation");

// logout
const logout = () => {
  const pinToken = useCookie("auth.pinToken");
  const cookies = useCookie("auth.token");
  profile.value = null;
  posPermission.value = null;
  managementPermission.value = null;
  isOneManagementPermissionActive.value = null;
  isOnePosPermissionActive.value = null;
  branch.value = null;
  cookies.value = null;

  if (pinToken.value) {
    window.location.href = `/pin-login?token=${pinToken.value}`;
  } else if (terminalId.value) {
    window.location.href = "/?terminal=1";
    pinToken.value = null;
  } else {
    terminalId.value = null;
    terminalName.value = null;
    pinToken.value = null;
    window.location.href = "/";
  }
};

provide("the-header", {
  logout,
});
</script>
